import { useNavigate, useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import HomeIcon from "./NavigationIcons/HomeIcon";
import JobSearchIcon from "./NavigationIcons/JobSearchIcon";
import ReferralIcon from "./NavigationIcons/ReferralIcon";
import ProfileIcon from "./NavigationIcons/ProfileIcon";

const useStyles = makeStyles({
  container: {
    bottom: "0",
    position: "fixed",
    width: "100%",
    backgroundColor: "#660872",
    zIndex: 1000,
    paddingTop: "2px",
    paddingLeft: "4px",
    paddingRight: "4px",
    paddingBottom: "16px",
    display: "flex",
    justifyContent: "center",
  },
  navigationBar: {
    backgroundColor: "#121212",
    borderRadius: "12px",
    border: "0.5px solid #FFFFFF",
    width: "95%",
    color: "white",
    marginBottom: "4px",
    height: "60px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  tab: {
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingTop: "4px",
    color: "rgba(255, 255, 255, 0.4)",
    "&.selected": {
      color: "rgba(255, 255, 255)",
      border: "1px solid #FFFFFF",
      borderRadius: "8px",
    },
  },
});

function NavigationBar() {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  // Function to check if the current path matches the button's path
  const isSelected = (path: string) => location.pathname === path;

  return (
    <Box className={classes.container}>
      <Box className={classes.navigationBar}>
        <Box
          className={`${classes.tab} ${isSelected("/") ? "selected" : ""}`}
          onClick={() => navigate("/")}
        >
          <HomeIcon color={isSelected("/") ? "#FFFFFF" : undefined} />
        </Box>
        <Box
          className={`${classes.tab} ${
            isSelected("/openings") ? "selected" : ""
          }`}
          onClick={() => navigate("/openings")}
        >
          <JobSearchIcon
            color={isSelected("/openings") ? "#FFFFFF" : undefined}
          />
        </Box>
        <Box
          className={`${classes.tab} ${
            isSelected("/referral") ? "selected" : ""
          }`}
          onClick={() => navigate("/referral")}
        >
          <ReferralIcon
            color={isSelected("/referral") ? "#FFFFFF" : undefined}
          />
        </Box>
        <Box
          className={`${classes.tab} ${
            isSelected("/profile") ? "selected" : ""
          }`}
          onClick={() => navigate("/profile")}
        >
          <ProfileIcon color={isSelected("/profile") ? "#FFFFFF" : undefined} />
        </Box>
      </Box>
    </Box>
  );
}

export default NavigationBar;
